import React, { useEffect, useRef, useState } from 'react';
import Title from '../../components/Title';
import { Link, useLocation } from 'react-router-dom';
import postNord from '../../images/events/Nord/base.jpg';
import postCentro from '../../images/events/Centro/base.jpg';
import postSud from '../../images/events/Sud/base.jpg';
import postCoppa from '../../images/events/Coppa Italia/base.jpg';
import assolutoMobile from '../../images/events/Assoluto/base.jpg';
import assolutoDesktop from '../../images/events/Assoluto/desktop.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { getEvents } from '../../redux/actions/eventActions';
import { BiMap } from 'react-icons/bi';

const Competizioni = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { events } = useSelector((state) => state.events);
  const dispatch = useDispatch();

  // Use the useLocation hook from react-router-dom to access the current location object
  const location = useLocation();

  // Extract the hash from the location object and remove the '#' at the start
  const hash = location.hash?.substring(1);

  // Define a function to smoothly scroll to a specific element by its id
  const scrollTo = (id) => {
    // Get the element by id
    const element = document.getElementById(id);
    // Scroll to the element smoothly
    element?.scrollIntoView({ behavior: 'smooth' });
  };

  // Use the useEffect hook to perform side effects
  useEffect(() => {
    // If there is a hash in the URL, scroll to the corresponding element
    if (hash) {
      scrollTo(hash);
    }
    // Dispatch the getEvents action to fetch events
    dispatch(getEvents());
  }, [dispatch, hash]); // Only re-run the effect if dispatch or hash changes

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const refs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('slide-in');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: [0, 0.25] } // trigger when 50% of the element is visible
    );

    refs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  });

  // Get event with tappa = tappa
  const getEvent = (tappa) => {
    let event = events?.find(
      (event) => event.tappa === tappa && event.tipo === 'Completa'
    );
    if (!event) event = events?.find((event) => event.tappa === tappa);
    return event;
  };

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  };

  const renderAcquista = (tappa) => {
    const event = getEvent(tappa);
    if (!event) return null;

    if (event && event.active) {
      return (
        <Link to={`../store/tickets/${tappa}`} className='btn'>
          Acquista
        </Link>
      );
    }

    if (
      event &&
      !event.active &&
      new Date(event.ticket_end_date) < new Date()
    ) {
      return <p className='btn not-active'>Non disponibile</p>;
    }
    return (
      <p className='btn not-active'>
        Disponibile dal {formatDate(event?.ticket_start_date)}
      </p>
    );
  };

  return (
    <>
      <Title title='Competizioni' />
      <main className='competizioniPage'>
        <div className='head'>
          <h1>Competizioni</h1>
          <p>
            RESISTANCE CALISTHENICS ENDURANCE ITALIA RICONOSCE PER LA STAGIONE
            AGONISTICA 2025 LE SEGUENTI TIPOLOGE DI GARE:
          </p>
          <div className='links'>
            <Link to='#campionatoText' className='btn'>
              Campionato Italiano
            </Link>
            <Link to='#assolutoText' className='btn'>
              Assoluto Italia
            </Link>
            <Link to='#coppaItaliaText' className='btn'>
              Coppa Italia
            </Link>
            <Link to='#premi' className='btn'>
              Premi
            </Link>
          </div>
        </div>
        <div className='description'>
          <h2 id='campionatoText'>
            Campionato Italiano Endurance Calisthenics
          </h2>
          <p>
            il campionato italiano darà la possibilità di iscriversi a due
            differenti tipologie di competizioni:{' '}
            <b>Gara Completa e Gara Standard</b>
          </p>
          <section className='recapGare'>
            <div className='completa'>
              <h3>Gara Completa</h3>
              <div className='list'>
                <p>Max Rep (mu, dip, pull, push)</p>
                <p>Battle 1vs1</p>
                <p>Alzate valide per i Record Italiani</p>
              </div>
              <hr />
              <p>Qualificante per Assoluto Italia</p>
            </div>
            <div className='standard'>
              <h3>Gara Standard</h3>
              <div className='list'>
                <p>Max Rep (pull & push)</p>
                <p>Battle 1vs1</p>
              </div>
              <hr />
              <p>Non qualificante per Assoluto Italia</p>
            </div>
          </section>
          <p>
            Le gare saranno strutturate durante l'anno in tre date differenti
            ciascuna della durata di due giorni e si troveranno al nord, al
            centro e al sud Italia.
          </p>
          <p>
            L'iscrizione dell'atleta agli eventi Resistance è esclusivamente
            valida all'accesso della Gara Max Rep. Ogni altro format di gara
            sar&agrave; disponibile solo per gli atleti che supereranno la Gara
            Max Rep.
          </p>

          <h4>
            La seguenti modalità saranno replicate per tutte e tre le date:
          </h4>
          <p>
            La prima giornata prevederà la divisione per classe di peso e sesso,
            successivamente inizierà la Gara Max Rep sia per la Gara Completa
            che per la Gara Standard. <br /> La prima giornata terminerà con la
            Gara Battle 1vs1 della Competizione Standard, mentre la Gara Battle
            1vs1 della Competizione Completa si svolgerà durante la seconda
            giornata. <br /> &Egrave; prevista la divisione di peso per entrambe
            le tipologie di competizione.
          </p>

          <h4>Donna</h4>
          <ul>
            <li>Peso Open</li>
          </ul>
          <h4>Uomo</h4>
          <ul>
            <li>Pesi Leggeri / -70kg</li>
            <li>Pesi Medi / -80kg</li>
            <li>Pesi Massimi / +80kg</li>
          </ul>
          <div className='links'>
            <Link to='#completa' className='btn'>
              Gara Completa
            </Link>
            <Link to='#standard' className='btn'>
              Gara Standard
            </Link>
          </div>

          <h4 id='completa'>Gara Completa</h4>
          <p>
            Gli atleti andranno nell’ordine assegnato dallo staff all'interno di
            ogni classe di peso e svolgeranno le prove di gara. Il primo format
            (Max Rep) prevede l'esecuzione di 4 esercizi standardizzati, in
            ordine muscle up alla sbarra, dip alle parallele, pull up alla
            sbarra e push up alla sbarra. Ad ogni atleta sarà concesso n°1 set
            unbroken per esercizio. I set saranno giudicati dagli ufficiali
            preposti.
          </p>
          <p>
            Tra un esercizio e l'altro sarà concesso un solo minuto di pausa e
            una volta completati gli esercizi sarà calcolato il totale del
            singolo atleta sommando tutte le ripetizioni ritenute valide dai
            giudici ottenute nelle diverse alzate.
          </p>
          <p>
            Per le categorie femminili il muscle up è opzionale quindi non è
            necessario fare almeno n°1 rep valida.
          </p>
          <p>
            I coefficienti di punteggio degli esercizi della fase max rep sono i
            seguenti: muscle up 2.5p, dip 1p, pull 1.5p, push up 1p. Tramite i
            punteggi ottenuti nella gara max rep verranno decretati n°12 atleti
            maschi (quattro per ogni classe di peso) e n°8 atlete femmine
            (quattro per ogni classe di peso) che accederanno alla seconda
            giornata. Al termine della prima giornata vi sarà la consegna dei
            premi inerenti alla Gara Max Rep.
          </p>
          <div className='links'>
            <Link className='btn' to='../best'>
              Vai ai record
            </Link>
          </div>
          <p>
            Nella seconda giornata gli atleti selezionati verranno abbinati ad
            un avversario a seconda del totale realizzato nella gara max rep
            sempre rispettando gli abbinamenti per classe di peso, il totale
            maggiore realizzato verra accoppiato con il totale minore realizzato
            all'interno della propria classe di peso. La gara battle 1vs1 sara
            divisa in due fasi:
          </p>
          <ol>
            <li>
              Nella prima fase gli atleti si sfideranno in un circuito a
              sorpresa ad eliminazione diretta contro il loro avversario. Passa
              il turno chi chiude per primo il circuito. Il muscle up non sarà
              presente per le categorie femminili in questa sezione di gara.
              <br />
              Gli esercizi ufficiali da circuito per la gara completa sono:
              <ul>
                <li>muscle up</li>
                <li>pull up</li>
                <li>dips</li>
                <li>bar dips</li>
                <li>bar push up </li>
                <li>air squat</li>
                <li>squat box jump</li>
                <li>jump squat</li>
              </ul>
              Saranno presenti obbligatoriamente in questa fase per uomo 4
              esercizi di upper e 1 di lower, mentre per donna 3 di upper e 1 di
              lower.
            </li>
            <li>
              Nella seconda fase si scontreranno i due vincitori di ogni battle
              per la propria classe di peso tra di loro, mentre chi avrà perso
              lo scontro si affronterà per il terzo posto del podio della sua
              classe.
              <br />
              La fase prevede che si completi un ulteriore circuito a sorpresa
              ad eliminazione diretta.
              <br />
              In questa fase il muscle up sarà obbligatorio per gli uomini in
              aggiunta a 3 esercizi di upper e 1 esercizio di lower.
              <br />
              In questa fase il pull up sar&agrave; obbligatorio per le donne in
              aggiunta a 2 esercizi di upper e 1 esercizio di lower.
              <br />I vincitori di questa fase decreteranno il piazzamento sul
              podio della classe di peso e tutti gli atleti sul podio otterrano
              la PRO CARD di qualifica per l'Assoluto Italia. Il Podio delle
              Battle decreterà la conclusione della Tappa.
            </li>
          </ol>
          <div className='links'>
            <Link className='btn' to='#premi'>
              Vai ai premi
            </Link>
            <Link className='btn' to='../hallOfFame'>
              VAI alle PRO CARD
            </Link>
          </div>

          <h4 id='standard'>Gara Standard</h4>
          <p>
            Gli atleti andranno nell’ordine assegnato dallo staff all'interno di
            ogni classe di peso e svolgeranno le prove di gara. Il primo format
            (Max Rep) prevede l'esecuzione di 2 esercizi standardizzati, in
            ordine pull up alla sbarra e push up alla sbarra. Ad ogni atleta
            sarà concesso n°1 set unbroken per esercizio. I set saranno
            giudicati dagli ufficiali preposti.
          </p>
          <p>
            Tra un esercizio e l'altro saranno concessi due minuti di pausa e
            una volta completati gli esercizi sarà calcolato il totale del
            singolo atleta sommando tutte le ripetizioni ritenute valide dai
            giudici ottenute nelle diverse alzate.
          </p>
          <p>
            La ripetizione del pull up verrà considerata 1.5p, mentre quella del
            push up 1p. Tramite i punteggi ottenuti nella gara max rep verranno
            decretati n°12 atleti maschi (quattro per ogni classe di peso) e n°8
            atlete femmine (quattro per ogni classe di peso) che accederanno
            alla seconda fase svolta sempre nella prima giornata di gara.
          </p>
          <p>
            Nella seconda fase gli atleti selezionati verranno abbinati ad un
            avversario a seconda del totale realizzato nella gara max rep sempre
            rispettando gli abbinamenti per classe di peso, il totale maggiore
            realizzato verra accoppiato con il totale minore realizzato
            all'interno della propria classe di peso. La gara battle 1vs1 sara
            divisa in due fasi:
          </p>
          <ol>
            <li>
              Nella prima fase gli atleti si sfideranno in un circuito a
              sorpresa ad eliminazione diretta contro il loro avversario. Passa
              il turno chi chiude per primo il circuito.
              <br />
              Gli esercizi ufficiali da circuito per la gara standard sono:
              <ul>
                <li>pull up</li>
                <li>dips</li>
                <li>bar push up </li>
                <li>air squat</li>
                <li>jump squat</li>
              </ul>
              Saranno presenti obbligatoriamente in questa fase 2 esercizi di
              upper e 1 esercizio di lower.
            </li>
            <li>
              Nella seconda fase si scontreranno i due vincitori di ogni battle
              per la propria classe di peso tra di loro, mentre chi avrà perso
              lo scontro si affronterà per il terzo posto del podio della sua
              classe.
              <br />
              La fase prevede che si completi un ulteriore circuito a sorpresa
              ad eliminazione diretta.
              <br />
              Gli esercizi presenti in questa fase, sia per uomo che per donna,
              saranno sempre 3 esercizi di upper e 1 di lower.
              <br />I vincitori di questa fase decreteranno il piazzamento sul
              podio della classe di peso della Gara Standard e per concludere la
              prima giornata di gara seguirà la premiazione Pull & Push
            </li>
          </ol>

          <div className='links'>
            <Link className='btn' to='../competizioni#premi'>
              Vai ai premi
            </Link>
          </div>

          <p>
            Con l'iscrizione all'evento l'atleta avrà l’accesso alla prima
            giornata (Max Rep) della gara per la quale ha acquistato il ticket.{' '}
            <br /> L’iscrizione del ticket sarà nominale e con la prima
            partecipazione ad un evento Resistance sarà obbligatorio anche
            pagare la copertura assicurativa che rilascia il tesserino tecnico
            ASI per poter gareggiare con noi 365 giorni. <br /> L’atleta non
            sarà vincolato a partecipare ad un unico evento del campionato
            italiano anche qualora egli si fosse già qualificato all’Assoluto
            Italia. Nel caso in cui l’atleta in questione dovesse iscriversi ad
            un'altra gara del campionato potrà prendere parte alla fase{' '}
            <b>Max Rep</b>, senza poter accedere alla fase <b>Battle1vs1</b>{' '}
            <br /> Questo poichè Resistance introdurrà i record italiani di
            endurance, consultabili nella pagina dei best ed un atleta sarà
            libero di partecipare alle competizioni presenti sia al nord che al
            centro e al sud per vincere i record rilasciati su tutto il suolo
            italiano. <br />
          </p>
          <p>
            Per ogni evento verrà stipulata una convenzione con hotel limitrofi
            alla zona della gara in questione, per agevolare l'atleta negli
            spostamenti.
          </p>

          <div className='links'>
            <Link className='btn' to={`../store/tickets/Nord`}>
              Acquista ticket atleta
            </Link>
          </div>

          <h4 id='assolutoText'>ASSOLUTO ITALIA ENDURANCE CALISTHENICS</h4>
          <p>
            L’evento si terrà alla fine dell’anno presso la prestigiosa Arena di
            Monza e comprenderà il campione in carica per ogni categoria, i
            migliori 9 pesi leggeri, medi, massimi maschili e le 6 migliori
            atlete assolute femminili che hanno ottenuto una PRO CARD, ovvero i
            podi ottenuti nelle battle1vs1 delle tre diverse gare qualificanti
            del campionato italiano della GARA COMPLETA.
          </p>
          <p>
            L’evento ASSOLUTO ITALIA è l’evento che decreterà il campione
            italiano maschile di categoria e la campionessa italiana assoluta.
            L’evento si chiuderà poi con la finale tra i vincitori maschili di
            categoria per stabilire il campione italiano ASSOLUTO.
          </p>
          <ol>
            <li>
              <h5>PRESS CONFERENCE + WEIGHT IN</h5>
              <p>
                Precedentemente alla gara in una sessione di streaming gestita
                tramite PRESS CONFERENCE mandata in onda 2mesi prima dell’evento
                l’atleta conoscerà il primo circuito che dovrà affrontare nella
                first session, durante la conferenza verrà inoltre dichiarato
                l’ordine di partenza degli atleti in gara. Le prove di gara
                seguiranno questo ordine di categoria: FEMALE / -70kg / -80kg /
                +80kg. La partenza all’interno della propria categoria sarà
                dettata da un ranking basato sul piazzamento del podio delle
                tappe di qualifica per la finale. Il giorno dell’evento ASSOLUTO
                ITALIA gli atleti saranno tenuti a fare l’ingresso in campo gara
                con la divisa ufficiale in dotazione all’atleta affrontando la
                pesa ufficiale e rientrando in categoria, pena la squalifica
                dalla competizione. La pesa potrà essere svolta in
                pantaloncini/pantaloni, senza abbigliamento per la parte alta o
                con la divisa ufficiale, non sarà ammesso altro abbigliamento
                per l’ingresso, per la pesa l’atleta dovrà togliere le scarpe
                che saranno invece d’obbligo durante la competizione e
                l’ingresso.
              </p>
            </li>
            <li>
              <h5>FIRST SESSION – SET RANKING – SPEED SET + BLOCK SET</h5>
              <p>
                In questa prima fase saranno previsti due circuiti a tempo dove
                si sfideranno all’interno della propria classe di peso i
                9leggeri, 9medi, 9massimi e le 6donne assolute. Vi saranno 5
                eliminazioni da ogni classe per gli uomini e 2 eliminazioni per
                le donne al termine del completamento di tutti i partecipanti
                dei 2 circuiti. L’ eliminazione terrà conto del tempo impiegato
                per completare il circuito1 ed il circuito2 sommando i 2 tempi,
                quindi passeranno il turno i 4 migliori risultati nel totale dei
                due tempi da ogni classe. Il circuito1 verrà dichiarato nella
                press conference precedente l’evento mentre il circuito2 resterà
                a sorpresa fino al completamento del circuito1 il giorno stesso
                della gara. Gli esercizi in gara saranno quelli riconosciuti dal
                regolamento per la gara completa REPZ-4. Nel circuito1 per
                l’uomo saranno presenti obbligatoriamente 4 esercizi di upper.
                Per la donna 3 di upper. Il circuito1 prevede un time cup di 4’.
                L’ordine di partenza del circuito2 sarà lo stesso del circuito1
                e prevederà un circuito con 5 blocchi di cui 4 esercizi di upper
                e 1 di lower per l’uomo, ed un circuito con 4 blocchi di cui 4
                di upper e 1 di lower per la donna, il circuito2 prevede un time
                cup di 9’. Al termine del secondo circuito verrà ufficializzato
                il ranking di categoria della stagione e passeranno alla
                sessione successiva i 4 migliori risultati per ogni categoria.
              </p>
            </li>
            <li>
              <h5>
                SECOND SESSION – BATTLE 1vs1 – TITOLO DI CATEGORIA MASCHILE –
                ASSOLUTO FEMMINILE
              </h5>
              <p>
                CHAMPIONS BATTLE: <br />
                Si scontreranno in questa fase i 4 atleti di ogni categoria che
                hanno superato il SET RANKING, dove si affronteranno 1 contro 1
                in un circuito ad eliminazione diretta. Gli abbinamenti dei
                match verranno svolti basandosi sul ranking dove il quarto ed il
                terzo nel rank si affronteranno nella finalina per il terzo
                posto del podio e il primo e secondo nel rank si affronteranno
                per il primo e secondo posto del podio. L’obiettivo di questa
                fase sarà quello di completare il circuito prima del diretto
                avversario. L’uomo avrà in questa fase 4 esercizi di upper e 1
                esercizio di lower, mentre per la donna ci saranno 3 esercizi di
                upper, 1 di lower. Il circuito prevede un time cup di 12’. Solo
                ed esclusivamente il primo posto del podio e dunque il campione
                italiano di categoria accede alla fase successiva. Da questa
                fase esce la campionessa italiana assoluta.
              </p>
            </li>
            <li>
              <h5>THIRD SESSION – ASSOLUTO MASCHILE</h5>
              <p>
                PLAYOFF SET & ABSOLUTE BATTLE: <br />
                Questa fase prevede un circuito di selezione per decretare i due
                sfidanti finali per il titolo assoluto di campione italiano
                maschile, il set vedrà partecipare i 3campioni di categoria
                maschile dichiarati nella fase precedente durante la champions
                battle. Il playoff set prevede un circuito a tempo dove il terzo
                tempo migliore dei 3 conseguirà automaticamente il 3°posto del
                podio assoluto e i 2 tempi migliori si affronteranno
                nell’absolute set. Il playoff set prevede un circuito a sorpresa
                senza time cup, svolto singolarmente dai 2 campioni della classi
                peso (-70kg, -80kg, +80kg) seguendo l'ordine di gara. Il
                circuito prevede tutti gli esercizi da gara ufficiali da
                regolamento ed avrà un volume totale di ripetizioni pari al
                miglior punteggio sul total del max rep delle gare del
                campionato registrato durante l’attuale stagione in corso.
                Esempio volume playoff set stagione 2024: 177reps. Ovvero il
                total di 177p, che figura come il più alto registato in questa
                stagione. Di seguito vedremo la battle finale che chiude
                l’evento, ovvero l’absolute set, che prevede una battle 1vs1 con
                circuito a sorpresa e ad eliminazione diretta. Il set prevederà
                5esercizi di upper ed 1 di lower. Il set prevederà un blocco
                fisso iniziale di intensità o di volume che sarà deciso quale
                attuare dall’atleta dei 2 arrivati in finale con il tempo
                migliore realizzato nel Playoff set. Nel caso venga scelto il
                blocco di intensità il set sarà composto da
                5mu/10bardip/10pullup/20dip con un sovraccarico di 10kg
                impostato tramite disco calibrato del brand “Insane Stone” ed
                annessa cintura con cordino. Nel caso venga invece scelto il
                blocco di volume il set sarà composto da
                10mu/20bardip/20pullup/5mu/20dip. In caso di scelta del blocco
                di intensità l’atleta partirà dunque con il blocco
                sovraccaricato e terminato l’ultima rep con 10kg potrà togliersi
                in autonomia o con il supporto di un giudice la cintura e
                proseguire con il set a corpo libero. In caso di scelta del
                blocco di volume l’alteta partirà già a corpo libero e
                proseguirà poi come nel caso del blocco d’intensità con la
                restante parte a sorpesa del circuito, rigorosamente a corpo
                libero. Da questa fase uscirà il campione italiano
                assoluto maschile.
              </p>
            </li>
          </ol>
          <div className='links'>
            <Link className='btn' to='../hallOfFame'>
              Vai alle PRO CARD
            </Link>
            <Link className='btn' to={`../store/tickets/Assoluto`}>
              Acquista Ticket Spettatore
            </Link>
          </div>

          <h4 id='coppaItaliaText'>COPPA ITALIA ENDURANCE CALISTHENICS</h4>
          <p>
            L’evento COPPA ITALIA è un evento a sé stante presso la palestra
            Street Gorilla Gym per stabilire il miglior atleta di categoria età
            e peso standard in Italia. L’evento COPPA ITALIA è un evento a sé
            stante presso la palestra Street Gorilla per stabilire il miglior
            atleta di categoria peso standard in Italia.Coppa Italia si rinnova
            in una chiave promozionale per ogni età, creando le due categorie di
            gara ufficiali NEXT GEN e MASTER all'interno della quale seguirà la
            divisione per peso classica. L’evento è a se stante rispetto le gare
            del campionato italiano ed è su iscrizione. Le categorie presenti
            saranno per uomo -70kg, -80kg e +80kg, mentre per donna sarà open
            weight. Le categorie di età saranno sia per uomo che per donna le
            stesse. NEXT GEN racchiude la fascia junior -23 e la fascia sub
            junior -18. MASTER racchiude la fascia master I +40 e la
            fascia master II +50.
          </p>
          <p>
            Gli esercizi da gara ufficiali di Coppa Italia sono:
            <ul>
              <li>pullup</li>
              <li>dip</li>
              <li>barpushup</li>
              <li>boxsquat</li>
              <li>jumpsquat</li>
              <li>airsquat</li>
            </ul>
            la donna non prevede il dip tra gli esercizi da gara di Coppa
            Italia.
          </p>
          <ol>
            <li>
              <h5>PRESS CONFERENCE + WEIGHT IN + APPLICATION SET</h5>
              <p>
                Precedentemente alla gara in una sessione di streaming gestita
                tramite PRESS CONFERENCE mandata in onda 1 mese prima
                dell’evento l’atleta conoscerà il circuito di qualifica sia
                maschile che femminile che dovrà affrontare per rientrare in
                classifica ed accedere alla fase successiva. Il circuito è
                denominato “Application set” ed ha un time cup di 5’. La gara
                prevede una graduatoria per ogni categoria peso stimata in base
                al tempo svolto nell’application set. Il giorno della gara prima
                di iniziare le sfide è prevista la pesa ufficiale per rientrare
                in categoria.
              </p>
            </li>
            <li>
              <h5>SET RANKING + BATTLE 1vs1</h5>
              <p>
                In base ai risultati di tempo nell’application set troveremo la
                classifica “set ranking” che vedrà inseriti i migliori
                6risultati per ogni classe di peso. Il 3°posto nel ranking
                ottiene automaticamente la terza postazione del podio della sua
                classe di peso, mentre il 1° ed il 2° nel rank si affronteranno
                in un circuito a sorpresa ad elimiazione diretta 1vs1. Il
                circuito finale, sia maschile che femminile prevede un time cup
                di 7’. Per l’uomo gli esercizi proposti sono 3 di upper e 2 di
                lower body, mentre per la donna 2 di upper e 2 di lower body. Da
                questa fase usciranno il 1° ed il 2° posto del podio di
                ogni classe di peso.
              </p>
            </li>
          </ol>
          <div className='links'>
            <Link className='btn' to={`../store/tickets/Coppa Italia`}>
              Acquista Ticket Atleta
            </Link>
          </div>

          <div className='record_premi' id='premi'>
            <h4>RECORD E PREMI</h4>
            <p>
              Le premiazioni qui di seguito avverranno sia per la singola tappa
              che per l’evento Finale. Per ogni voce si fa riferimento sia al
              campo maschile che rispettivamente al campo femminile.
            </p>
            <ol>
              <li>
                <span>CAMPIONE IN CARICA – ASSOLUTO ITALIA</span> <br />
                colui che si posizionerà per primo all’evento Finale dell’anno
                in corso nell'Overall ed otterrà cintura e contratto.
              </li>
              <li>
                <span>PODIO ANNUALE – ASSOLUTO ITALIA</span>
                <br /> le prime tre posizioni all’evento finale dell’anno in
                corso nell'Overall.
              </li>
              <li>
                <span>PODIO DELL'ASSOLUTO PER CLASSI DI PESO</span>
                <br />
                le prime tre posizioni di ogni classe all’evento finale
                dell’anno in corso.
              </li>
              <li>
                <span>PODIO COPPA ITALIA STANDARD PER CLASSI DI PESO</span>
                <br />
                le prime tre posizioni di ogni classe all’evento finale
                dell’anno in corso.
              </li>
              <li>
                <span>
                  PODIO DELLA TAPPA PER CLASSI DI PESO - CAMPIONATO ITALIANO -
                  GARA COMPLETA
                </span>
                <br /> e prime tre posizioni nelle rispettive classi di peso
                nella singola tappa, determinate alla fine delle Battle1vs1 dopo
                la qualfica del max rep “Repz-4”.
              </li>
              <li>
                <span>
                  PODIO DELLA TAPPA PER CLASSI DI PESO - CAMPIONATO ITALIANO -
                  GARA STANDARD
                </span>
                <br /> le prime tre posizioni nelle rispettive classi di peso
                nella singola tappa, determinate alla fine delle Battle1vs1 dopo
                la qualifica del max rep “Pull and Push”
              </li>
              <li>
                <span>PRO CARD - CAMPIONATO ITALIANO - GARA COMPLETA</span>
                <br />
                tessera ottenuta da ogni atleta posizionato sul podio a fine
                battle1vs1 per ogni classe di peso, con la pro card si avrà
                l’accesso all’assoluto italia e la t-shirt ufficiale della
                nazionale.
              </li>
              <li>
                <span>
                  ASSOLUTI PER CATEGORIA D’ET&Agrave; DELLA TAPPA – CAMPIONATO
                  ITALIANO - GARA COMPLETA
                </span>
                <br />
                il miglior atleta nella propria categoria d’etm verrà premiato
                al termine della prima gara – Max rep di ogni singola tappa.
              </li>
              <li>
                <span>
                  RECORD DELLA TAPPA - CAMPIONATO ITALIANO - GARA COMPLETA
                </span>
                <br /> dopo la Prima gara – Max rep di ogni singola tappa
                vengono consegnati i record “Best Reps” suddivisi come segue:
                <ul>
                  <li>Miglior Totale assoluto</li>
                  <li>
                    Miglior Totale per classi di peso (leggeri, medi, massimi,
                    open donna)
                  </li>
                  <li>Miglior Totale Muscle ups</li>
                  <li>Miglior Totale Dips</li>
                  <li>Miglior Totale Pull ups </li>
                  <li>Miglior Totale Push ups</li>
                </ul>
              </li>
            </ol>
            <p>
              Durante l’evento di fine anno “ASSOLUTO ITALIA” l’atleta
              partecipante avrà l’obbligo di fare l’ingresso iniziale sul campo
              con la maglia ufficiale che gli sarà stata preventivamente
              consegnata. Tale indumento non sarà obbligatorio da indossare
              durante la gara stessa. All’ASSOLUTO ITALIA si accede solo ed
              esclusivamente di diritto ottenendo una PRO CARD in corso di
              validità della stagione agonistica corrente.
            </p>
          </div>
        </div>

        <div className='tappa slide' id='nord' ref={refs[0]}>
          <img src={postNord} alt='' />
          <div className='content'>
            <h3>Campionato Italiano / Nord</h3>
            <Link
              className='indirizzo'
              to='https://maps.app.goo.gl/AFG9dAPurfgAAeLg6'
              target='_blank'
              rel='noreferrer'>
              <BiMap />
              <span>Upgrade Training Center </span> Via Po, 2, 20871 Torri
              Bianche MB
            </Link>

            {renderAcquista('Nord')}
          </div>
        </div>
        <div className='tappa slide' id='centro' ref={refs[1]}>
          <img src={postCentro} alt='' />
          <div className='content'>
            <h3>Campionato Italiano / Centro</h3>
            <Link
              className='indirizzo'
              to='https://maps.app.goo.gl/C46vr13r6z2Vdj466'
              target='_blank'
              rel='noreferrer'>
              <BiMap />
              <span>Heart Of Steel </span> Via Francesco Paolo Bonifacio, 151,
              00156 Roma
            </Link>
            {renderAcquista('Centro')}
          </div>
        </div>
        <div className='tappa slide' id='sud' ref={refs[2]}>
          <img src={postSud} alt='' />
          <div className='content'>
            <h3>Campionato Italiano / Sud</h3>
            <Link
              className='indirizzo'
              to='https://maps.app.goo.gl/n8sdcSbQY73hqnu16'
              target='_blank'
              rel='noreferrer'>
              <BiMap />
              <span>Body Master Underground Lecce</span> Via B. Croce, 45, 73100
              Lecce
            </Link>
            {renderAcquista('Sud')}
          </div>
        </div>
        <div className='tappa slide' id='coppaItalia' ref={refs[4]}>
          <img src={postCoppa} alt='' />
          <div className='content'>
            <h3>Coppa Italia</h3>
            <Link
              className='indirizzo'
              to='https://maps.app.goo.gl/ZPgnEGav7oNz8gZe9'
              target='_blank'
              rel='noreferrer'>
              <BiMap />
              <span>
                Street Gorilla Gym, Via Vittorio Alfieri, 72, 20089 Rozzano MI
              </span>
            </Link>
            {renderAcquista('Coppa Italia')}
          </div>
        </div>
        <div id='assoluto' className='slide' ref={refs[3]}>
          <img src={isMobile ? assolutoMobile : assolutoDesktop} alt='' />
          <div className='content'>
            <p>
              <b>Biglietto Spettatore </b>
              <br /> Include un posto a sedere e flyer dedicato all'evento
            </p>
            <Link
              className='indirizzo'
              to='https://maps.app.goo.gl/nBqGuUdEgRhVPm95A'
              target='_blank'
              rel='noreferrer'>
              <BiMap />
              <span>
                Opiquad Arena - Viale Gian Battista Stucchi, 20900 Monza MB
              </span>
            </Link>
            <p className='btn'>Non Disponibile</p>
          </div>
        </div>
      </main>
    </>
  );
};

export default Competizioni;
