import React from 'react';
import { Link } from 'react-router-dom';

// Icons
import { AiOutlineInstagram } from 'react-icons/ai';
import { BiLogoYoutube } from 'react-icons/bi';

const Social = () => {
  return (
    <section className='social'>
      <h2>Rimani aggiornato</h2>
      <Link
        to='https://www.instagram.com/resistance.italia/'
        target='_blank'
        rel='noreferrer'
        className='socialCard'>
        <div className='instagram'>
          <AiOutlineInstagram />
        </div>
        <h4>@resistance.italia</h4>
        <p>La nuova Frontiera della Resistenza</p>
        <button className='btn'>Follow us</button>
      </Link>
      <Link
        to='https://www.youtube.com/@Resistance-italia/'
        target='_blank'
        rel='noreferrer'
        className='socialCard'>
        <div className='youtube'>
          <BiLogoYoutube />
        </div>
        <h4>Resistance Italia</h4>
        <p>Entra in una nuova dimensione</p>
        <button className='btn'>Iscriviti</button>
      </Link>
      <Link
        to='https://www.instagram.com/nogravity.workout/'
        target='_blank'
        rel='noreferrer'
        className='socialCard'>
        <div className='instagram'>
          <AiOutlineInstagram />
        </div>
        <h4>@nogravity.workout</h4>
        <p>Calisthenics evolution concept</p>
        <button className='btn'>Follow us</button>
      </Link>
      <Link
        to='https://www.youtube.com/@NoGRAVITYWORKOUT'
        target='_blank'
        rel='noreferrer'
        className='socialCard'>
        <div className='youtube'>
          <BiLogoYoutube />
        </div>
        <h4>No Gravity Workout</h4>
        <button className='btn'>Iscriviti</button>
      </Link>
      <Link
        to='https://www.instagram.com/resistance.athlete/'
        target='_blank'
        rel='noreferrer'
        className='socialCard'>
        <div className='instagram'>
          <AiOutlineInstagram />
        </div>
        <h4>@resistance.athlete</h4>
        <p>News a 360° sull'allenamento</p>
        <button className='btn'>Follow us</button>
      </Link>
    </section>
  );
};

export default Social;
