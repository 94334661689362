import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  clearAthleteInfo,
  clearInsurance,
  getEvent,
  postAthleteInfo,
  removeTicketFromCart,
} from '../../redux/actions/eventActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useParams } from 'react-router-dom';
import { getUserDetails } from '../../redux/actions/userActions';
import Protected from '../../components/Protected';
import { getCart } from '../../redux/actions/storeActions';
import Title from '../../components/Title';

const AthleteInfo = () => {
  let [formSent, setFormSent] = useState(false);
  const { eventId } = useParams();
  const { event } = useSelector((state) => state.event);
  console.log(event?.name);
  const { user } = useSelector((state) => state.userDetails);
  const { tickets } = useSelector((state) => state.cart);
  const ticket = tickets?.filter((t) => t.event_id === Number(eventId))[0];
  const { data, error } = useSelector((state) => state.athleteInfo);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserDetails());
    dispatch(getEvent(eventId));
    dispatch(getCart());
  }, [dispatch, eventId]);

  const onSubmit = (formData) => {
    dispatch(postAthleteInfo(eventId, formData, ticket.id));
    dispatch(getCart());
    setFormSent(true);
  };

  const onClick = (e) => {
    e.stopPropagation();
    dispatch(clearInsurance());
    dispatch(clearAthleteInfo());
    dispatch(removeTicketFromCart(ticket.id));
  };

  const checkInsurance = () => {
    for (let i = 0; i < tickets?.length; i++) {
      if (tickets[i].insurance) {
        return true;
      }
    }
    return false;
  };

  const classi = [
    { id: 1, name: '< 70kg Pesi Leggeri - Uomo', value: 'Pesi Leggeri' },
    { id: 2, name: '< 80kg Pesi Medi - Uomo', value: 'Pesi Medi' },
    { id: 3, name: '> 80kg Pesi massimi - Uomo', value: 'Pesi Massimi' },
    { id: 4, name: 'Open - Donna', value: 'Open' },
  ];
  const etaCoppaItalia = [
    { id: 1, name: '< 18 - Next Gen - Sub Junior', value: 'Sub Junior' },
    { id: 2, name: '< 23 - Next Gen - Junior', value: 'Junior' },
    { id: 3, name: '> 40 - Master I', value: 'Master I' },
    { id: 4, name: '> 50 - Master II', value: 'Master II' },
  ];

  return (
    <main className='athleteInfoPage'>
      <Title title='Informazioni' />
      <Protected />
      {/* {!ticket && !loading && <Navigate to='../store/cart' />} */}
      {data?.event === ticket?.event_id &&
        formSent &&
        (event?.needs_insurance &&
        (!user?.insurance || user?.insurance?.expire_date <= event.end_date) &&
        !checkInsurance() ? (
          <Navigate to='../account/insurance' />
        ) : (
          <Navigate to='../store/cart' />
        ))}

      <h1>Dati Atleta</h1>
      <small>Inserisci i tuoi dati per la Gara</small>
      <section className='content'>
        <div>
          {error && <p className='error'>{error}</p>}
          <form onSubmit={handleSubmit(onSubmit)}>
            <h4>1 - Classe di peso:</h4>
            <section className={`${errors.classe ? 'wrong' : ''}`}>
              <select
                {...register('classe', { required: 'Campo obbligatorio' })}>
                {classi.map(({ id, name, value }) => {
                  return (
                    <option key={id} value={value}>
                      {name}
                    </option>
                  );
                })}
              </select>
              <label>Classe</label>
            </section>

            <hr />

            <h4>2 - Hai qualche patologia o problema di salute rilevante?</h4>
            <small>
              Verrai contattato nel caso per approfondire la tua situazione
            </small>
            <section className='checkbox'>
              <input
                type='checkbox'
                {...register('salute')}
                id='salute'
                placeholder=' '
              />
              <label
                htmlFor='salute'
                id='saluteLabel'
                className='checkboxLabel'>
                Sì
              </label>
              {errors.salute && (
                <p className='error'>{errors.salute.message}</p>
              )}
            </section>

            <hr />
            <h4>3 - Hai bisogno di un alloggio?</h4>
            <small>
              Nel caso verrai contattato da un membro del nostro staff per
              l’alloggio a te in convenzione
            </small>
            <section className='checkbox'>
              <input
                type='checkbox'
                {...register('alloggio')}
                id='alloggio'
                placeholder=' '
              />
              <label
                htmlFor='alloggio'
                id='alloggioLabel'
                className='checkboxLabel'>
                Sì
              </label>
              {errors.alloggio && (
                <p className='error'>{errors.alloggio.message}</p>
              )}
            </section>

            {event?.name === 'Coppa Italia 2025' && (
              <>
                <hr />
                <h4>4 - Classe di età</h4>
                <small>
                  Seleziona la tua classe di età per la Coppa Italia
                </small>
                <section className={`${errors.eta ? 'wrong' : ''}`}>
                  <select
                    {...register('eta', { required: 'Campo obbligatorio' })}>
                    {etaCoppaItalia.map(({ id, name, value }) => {
                      return (
                        <option key={id} value={value}>
                          {name}
                        </option>
                      );
                    })}
                  </select>
                  <label>Età</label>
                </section>
              </>
            )}

            <input type='submit' value='Procedi' className='btn' />
          </form>

          <Link
            className='deleteTicket'
            to='../store/cart'
            onClick={(e) => onClick(e)}>
            Elimina biglietto
          </Link>
        </div>
      </section>
    </main>
  );
};

export default AthleteInfo;
