import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Title from '../../components/Title';
import { useDispatch } from 'react-redux';
import { getCart } from '../../redux/actions/storeActions';
import 'react-multi-carousel/lib/styles.css';
import { Link, useLocation } from 'react-router-dom';
import ProductsCarousel from '../../layout/store/ProductsCarousel';
import { getEvents } from '../../redux/actions/eventActions';
import EventsCarousel from '../../layout/store/EventsCarousel';

const Store = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const hash = location.hash?.substring(1);

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (hash) {
      scrollTo(hash);
    }
    dispatch(getEvents());
  }, [dispatch, hash]);

  const memoizedCallback = useCallback(() => {
    dispatch(getCart());
    dispatch(getEvents());
  }, [dispatch]);

  useEffect(() => {
    memoizedCallback();
  }, [memoizedCallback]);

  const memoizedNordImage = useMemo(() => {
    return <img src={require('../../images/events/Nord/base.jpg')} alt='' />;
  }, []);
  const memoizedCentroImage = useMemo(() => {
    return <img src={require('../../images/events/Centro/base.jpg')} alt='' />;
  }, []);
  const memoizedSudImage = useMemo(() => {
    return <img src={require('../../images/events/Sud/base.jpg')} alt='' />;
  }, []);

  const refs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('slide-in');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: [0, 0.25] } // trigger when 50% of the element is visible
    );

    refs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  });

  return (
    <>
      <Title title='Store' />
      <main className='store'>
        <section className='ticketsContainer'>
          <Link
            to={`../store/tickets/Nord`}
            className='slide left'
            ref={refs[0]}>
            {memoizedNordImage}
          </Link>
          <Link
            to={`../store/tickets/Centro`}
            className='slide right'
            ref={refs[2]}>
            {memoizedCentroImage}
          </Link>
          <Link
            to={`../store/tickets/Sud`}
            className='slide left'
            ref={refs[3]}>
            {memoizedSudImage}
          </Link>
        </section>
        <section className='products'>
          <h1>Prodotti</h1>
          <h4>Enter in the Zone</h4>
          <nav>
            <Link to='#classic' className='btn btn-reversed'>
              Classic
            </Link>
            <Link to='#performance' className='btn btn-reversed'>
              Performance
            </Link>
            <Link to='#plus' className='btn btn-reversed'>
              Plus
            </Link>
            <Link to='#gare' className='btn btn-reversed'>
              Gare
            </Link>
          </nav>
          <hr />
          <div id='classic' className='zone'>
            <h1>Classic</h1>
            <ProductsCarousel zone='classic' />
          </div>
          <hr />
          <div id='performance' className='zone'>
            <h1>Performance</h1>
            <ProductsCarousel zone='performance' />
          </div>
          <hr />
          <div id='plus' className='zone'>
            <h1>Plus</h1>
            <ProductsCarousel zone='plus' />
          </div>
        </section>
        <section className='events' id='gare'>
          <h1>Biglietti</h1>
          <EventsCarousel />
        </section>
        {/* <section className='prossimamente'>
          <div ref={refs[2]} className='slide left'>
          </div>
        </section> */}
      </main>
    </>
  );
};

export default Store;
