import React, { useState } from 'react';
import Title from '../../components/Title';
import proCardFronte from '../../images/proCards/ProCardFronte.jpg';
import proCardRetro from '../../images/proCards/ProCardRetro.jpg';

import proCard_Assoluto2024_Overall from '../../images/proCards/Assoluto2024/Overall.jpg';
import proCard_Assoluto2024_Leggeri from '../../images/proCards/Assoluto2024/Leggeri.jpg';
import proCard_Assoluto2024_Medi from '../../images/proCards/Assoluto2024/Medi.jpg';
import proCard_Assoluto2024_Massimi from '../../images/proCards/Assoluto2024/Massimi.jpg';
import proCard_Assoluto2024_Female from '../../images/proCards/Assoluto2024/Female.jpg';

import proCard_Nord2024_1 from '../../images/proCards/Nord2024/Vincenzo.jpg';
import proCard_Nord2024_2 from '../../images/proCards/Nord2024/Filippo.jpg';
import proCard_Nord2024_3 from '../../images/proCards/Nord2024/Alessandro.jpg';
import proCard_Nord2024_4 from '../../images/proCards/Nord2024/Luca.jpg';
import proCard_Nord2024_5 from '../../images/proCards/Nord2024/Riccardo.jpg';
import proCard_Nord2024_6 from '../../images/proCards/Nord2024/Simone.jpg';
import proCard_Nord2024_7 from '../../images/proCards/Nord2024/Samuele.jpg';
import proCard_Nord2024_8 from '../../images/proCards/Nord2024/Dennis.jpg';
import proCard_Nord2024_9 from '../../images/proCards/Nord2024/Sergio.jpg';
import proCard_Nord2024_10 from '../../images/proCards/Nord2024/ChiaraC.jpg';
import proCard_Nord2024_11 from '../../images/proCards/Nord2024/ChiaraB.jpg';

import proCard_Centro2024_1 from '../../images/proCards/Centro2024/Maicol.jpg';
import proCard_Centro2024_2 from '../../images/proCards/Centro2024/GiuseppeG.jpg';
import proCard_Centro2024_3 from '../../images/proCards/Centro2024/Mattia.jpg';
import proCard_Centro2024_4 from '../../images/proCards/Centro2024/Gaetano.jpg';
import proCard_Centro2024_5 from '../../images/proCards/Centro2024/Nicholas.jpg';
import proCard_Centro2024_6 from '../../images/proCards/Centro2024/GiuseppeB.jpg';
import proCard_Centro2024_7 from '../../images/proCards/Centro2024/Daniele.jpg';
import proCard_Centro2024_8 from '../../images/proCards/Centro2024/Stefania.jpg';
import proCard_Centro2024_9 from '../../images/proCards/Centro2024/Ester.jpg';

import proCard_Sud2024_1 from '../../images/proCards/Sud2024/Gianmarco.jpg';
import proCard_Sud2024_2 from '../../images/proCards/Sud2024/Mirko.jpg';
import proCard_Sud2024_3 from '../../images/proCards/Sud2024/Gioele.jpg';
import proCard_Sud2024_4 from '../../images/proCards/Sud2024/Matteo.jpg';
import proCard_Sud2024_5 from '../../images/proCards/Sud2024/Alessio.jpg';
import proCard_Sud2024_6 from '../../images/proCards/Sud2024/Luca.jpg';
import proCard_Sud2024_7 from '../../images/proCards/Sud2024/Alex.jpg';
import proCard_Sud2024_8 from '../../images/proCards/Sud2024/Cristiano.jpg';
import proCard_Sud2024_9 from '../../images/proCards/Sud2024/Alessia.jpg';
import proCard_Sud2024_10 from '../../images/proCards/Sud2024/Maria.jpg';

import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';

const HallOfFame = () => {
  const [isProCard2024Visible, setIsProCard2024Visible] = useState(false);
  const [isNord2024Visible, setIsNord2024Visible] = useState(false);
  const [isCentro2024Visible, setIsCentro2024Visible] = useState(false);
  const [isSud2024Visible, setIsSud2024Visible] = useState(false);

  const toggleProCard2024Visibility = () => {
    setIsProCard2024Visible(!isProCard2024Visible);
  };

  const toggleNord2024Visibility = () => {
    setIsNord2024Visible(!isNord2024Visible);
  };

  const toggleCentro2024Visibility = () => {
    setIsCentro2024Visible(!isCentro2024Visible);
  };

  const toggleSud2024Visibility = () => {
    setIsSud2024Visible(!isSud2024Visible);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 894 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 894, min: 587 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 587, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <Title title='Hall of Fame' />
      <main className='hallOfFamePage'>
        <h1>Hall Of Fame</h1>
        <p>
          QUESTA PAGINA &Egrave; INTERAMENTE <b>DEDICATA ALLA MEMORIA</b> DI
          COLORO CHE SONO RIUSCITI AD AGGIUDICARSI UN POSTO TRA I{' '}
          <b>CAMPIONI</b>.
        </p>

        <div className='overall'>
          <h2>Overall Champion</h2>
          <div>
            <img src={proCard_Assoluto2024_Overall} alt='' />
            <Link
              to='https://www.instagram.com/maicolbeltrame/'
              target='_blank'
              rel='noreferrer'>
              @maicolbeltrame
            </Link>
            <h3>2024</h3>
          </div>
          <h2>Lightweight Champion</h2>
          <div>
            <img src={proCard_Assoluto2024_Leggeri} alt='' />
            <Link
              to='https://www.instagram.com/maicolbeltrame/'
              target='_blank'
              rel='noreferrer'>
              @maicolbeltrame
            </Link>
            <h3>2024</h3>
          </div>
          <h2>Middleweight Champion</h2>
          <div>
            <img src={proCard_Assoluto2024_Medi} alt='' />
            <Link
              to='https://www.instagram.com/simone.alfano_/'
              target='_blank'
              rel='noreferrer'>
              @simone.alfano_
            </Link>
            <h3>2024</h3>
          </div>
          <h2>Heavyweight Champion</h2>
          <div>
            <img src={proCard_Assoluto2024_Massimi} alt='' />
            <Link
              to='https://www.instagram.com/alex.fel3/'
              target='_blank'
              rel='noreferrer'>
              @alex.fel3
            </Link>
            <h3>2024</h3>
          </div>
          <h2>Female Champion</h2>
          <div>
            <img src={proCard_Assoluto2024_Female} alt='' />
            <Link
              to='https://www.instagram.com/stefiporsche/'
              target='_blank'
              rel='noreferrer'>
              @stefiporsche
            </Link>
            <h3>2024</h3>
          </div>
        </div>

        <button
          className='btn toggleProCards'
          onClick={toggleProCard2024Visibility}>
          Campioni 2024
        </button>

        {isProCard2024Visible && (
          <section className='proCard 2024'>
            <h1>PRO CARD 2024</h1>

            <button className='zoneBtn' onClick={toggleNord2024Visibility}>
              <h2>NORD</h2>
            </button>
            {isNord2024Visible && (
              <div className='nord'>
                <div className='weight'>
                  <h2>Lightweight</h2>
                  <Carousel
                    className='carousel'
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    removeArrowOnDeviceType={['desktop']}
                    autoPlaySpeed={3000}>
                    <div className='card'>
                      <img src={proCard_Nord2024_1} alt='' />
                      <h2>Vincenzio Antonio Blundo</h2>
                      <Link
                        to='https://www.instagram.com/enzo_raeulerrrr/'
                        target='_blank'
                        rel='noreferrer'>
                        @enzo_raeulerrrr
                      </Link>
                    </div>
                    <div className='card'>
                      <img src={proCard_Nord2024_3} alt='' />
                      <h2>Alessandro Passoni</h2>
                      <Link
                        to='https://www.instagram.com/alepassoni_/'
                        target='_blank'
                        rel='noreferrer'>
                        @alepassoni_
                      </Link>
                    </div>
                    <div className='card'>
                      <img src={proCard_Nord2024_2} alt='' />
                      <h2>Filippo Sesana</h2>
                      <Link
                        to='https://www.instagram.com/filippo.sesana/'
                        target='_blank'
                        rel='noreferrer'>
                        @filippo.sesana
                      </Link>
                    </div>
                  </Carousel>
                </div>
                <div className='weight'>
                  <h2>Middleweight</h2>
                  <Carousel
                    className='carousel'
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    removeArrowOnDeviceType={['desktop']}
                    autoPlaySpeed={3000}>
                    <div className='card'>
                      <img src={proCard_Nord2024_4} alt='' />
                      <h2>Luca Tempera</h2>
                      <Link
                        to='https://www.instagram.com/lord.tempera/'
                        target='_blank'
                        rel='noreferrer'>
                        @lord.tempera
                      </Link>
                    </div>
                    <div className='card'>
                      <img src={proCard_Nord2024_6} alt='' />
                      <h2>Simone Alfano</h2>
                      <Link
                        to='https://www.instagram.com/simone.alfano_/'
                        target='_blank'
                        rel='noreferrer'>
                        @simone.alfano_
                      </Link>
                    </div>
                    <div className='card'>
                      <img src={proCard_Nord2024_5} alt='' />
                      <h2>Riccardo Pisani</h2>
                      <Link
                        to='https://www.instagram.com/ilpisuu/'
                        target='_blank'
                        rel='noreferrer'>
                        @ilpisuu
                      </Link>
                    </div>
                  </Carousel>
                </div>
                <div className='weight'>
                  <h2>Heavyweight</h2>
                  <Carousel
                    className='carousel'
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    removeArrowOnDeviceType={['desktop']}
                    autoPlaySpeed={3000}>
                    <div className='card'>
                      <img src={proCard_Nord2024_7} alt='' />
                      <h2>Samuele Ramazzotti</h2>
                      <Link
                        to='https://www.instagram.com/samueleramazzotti/'
                        target='_blank'
                        rel='noreferrer'>
                        @samueleramazzotti
                      </Link>
                    </div>
                    <div className='card'>
                      <img src={proCard_Nord2024_8} alt='' />
                      <h2>Dennis Biagi</h2>
                      <Link
                        to='https://www.instagram.com/dennis_biagi/'
                        target='_blank'
                        rel='noreferrer'>
                        @dennis_biagi
                      </Link>
                    </div>
                    <div className='card'>
                      <img src={proCard_Nord2024_9} alt='' />
                      <h2>Sergio Limarelli</h2>
                      <Link
                        to='https://www.instagram.com/chef_sergio_limarelli/'
                        target='_blank'
                        rel='noreferrer'>
                        @chef_sergio_limarelli
                      </Link>
                    </div>
                  </Carousel>
                </div>
                <div className='weight'>
                  <h2>Female</h2>
                  <Carousel
                    className='carousel'
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    removeArrowOnDeviceType={['desktop']}
                    autoPlaySpeed={3000}>
                    <div className='card'>
                      <img src={proCard_Nord2024_10} alt='' />
                      <h2>Chiara Cavalleri</h2>
                      <Link
                        to='https://www.instagram.com/chiaracavalleri/'
                        target='_blank'
                        rel='noreferrer'>
                        @chiaracavalleri
                      </Link>
                    </div>
                    <div className='card'>
                      <img src={proCard_Nord2024_11} alt='' />
                      <h2>Chiara Biasi</h2>
                      <Link
                        to='https://www.instagram.com/6_freeky_9/'
                        target='_blank'
                        rel='noreferrer'>
                        @6_freeky_9
                      </Link>
                    </div>
                  </Carousel>
                </div>
              </div>
            )}

            <button className='zoneBtn' onClick={toggleCentro2024Visibility}>
              <h2> CENTRO</h2>
            </button>
            {isCentro2024Visible && (
              <div className='centro'>
                <div className='weight'>
                  <h2>Lightweight</h2>
                  <Carousel
                    className='carousel'
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    removeArrowOnDeviceType={['desktop']}
                    autoPlaySpeed={3000}>
                    <div className='card'>
                      <img src={proCard_Centro2024_1} alt='' />
                      <h2>Maicol Beltrame</h2>
                      <Link
                        to='https://www.instagram.com/maicolbeltrame/'
                        target='_blank'
                        rel='noreferrer'>
                        @maicolbeltrame
                      </Link>
                    </div>
                    <div className='card'>
                      <img src={proCard_Centro2024_2} alt='' />
                      <h2>Giuseppe Guida</h2>
                      <Link
                        to='https://www.instagram.com/giuseppeguida_sw/'
                        target='_blank'
                        rel='noreferrer'>
                        @giuseppeguida_sw
                      </Link>
                    </div>
                    <div className='card'>
                      <img src={proCard_Centro2024_3} alt='' />
                      <h2>Mattia Tessitore</h2>
                      <Link
                        to='https://www.instagram.com/_mattiatessitore_/'
                        target='_blank'
                        rel='noreferrer'>
                        @_mattiatessitore_
                      </Link>
                    </div>
                  </Carousel>
                </div>
                <div className='weight'>
                  <h2>Middleweight</h2>
                  <Carousel
                    className='carousel'
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    removeArrowOnDeviceType={['desktop']}
                    autoPlaySpeed={3000}>
                    <div className='card'>
                      <img src={proCard_Centro2024_4} alt='' />
                      <h2>Gaetano Guida</h2>
                      <Link
                        to='https://www.instagram.com/grl_calisthenics/'
                        target='_blank'
                        rel='noreferrer'>
                        @grl_calisthenics
                      </Link>
                    </div>
                    <div className='card'>
                      <img src={proCard_Centro2024_5} alt='' />
                      <h2>Nicholas Matassa</h2>
                      <Link
                        to='https://www.instagram.com/nicholasmatassaa /'
                        target='_blank'
                        rel='noreferrer'>
                        @nicholasmatassaa
                      </Link>
                    </div>
                    <div className='card'>
                      <img src={proCard_Centro2024_6} alt='' />
                      <h2>Giuseppe Bruno</h2>
                      <Link
                        to='https://www.instagram.com/giuseppebruno19888/'
                        target='_blank'
                        rel='noreferrer'>
                        @giuseppebruno19888
                      </Link>
                    </div>
                  </Carousel>
                </div>
                <div className='weight'>
                  <h2>Heavyweight</h2>
                  <Carousel
                    className='carousel'
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    removeArrowOnDeviceType={['desktop']}
                    autoPlaySpeed={3000}>
                    <div className='card'>
                      <img src={proCard_Centro2024_7} alt='' />
                      <h2>Daniele Colautti</h2>
                      <Link
                        to='https://www.instagram.com/danielecolauttii /'
                        target='_blank'
                        rel='noreferrer'>
                        @danielecolauttii
                      </Link>
                    </div>
                  </Carousel>
                </div>
                <div className='weight'>
                  <h2>Female</h2>
                  <Carousel
                    className='carousel'
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    removeArrowOnDeviceType={['desktop']}
                    autoPlaySpeed={3000}>
                    <div className='card'>
                      <img src={proCard_Centro2024_8} alt='' />
                      <h2>Stefania Menni</h2>
                      <Link
                        to='https://www.instagram.com/stefiporsche/'
                        target='_blank'
                        rel='noreferrer'>
                        @stefiporsche
                      </Link>
                    </div>
                    <div className='card'>
                      <img src={proCard_Centro2024_9} alt='' />
                      <h2>Ester Lo Iacono</h2>
                      <Link
                        to='https://www.instagram.com/the_dis.ester/'
                        target='_blank'
                        rel='noreferrer'>
                        @the_dis.ester
                      </Link>
                    </div>
                  </Carousel>
                </div>
              </div>
            )}

            <button className='zoneBtn' onClick={toggleSud2024Visibility}>
              <h2>SUD</h2>
            </button>
            {isSud2024Visible && (
              <div className='sud'>
                <div className='weight'>
                  <h2>Lightweight</h2>
                  <Carousel
                    className='carousel'
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    removeArrowOnDeviceType={['desktop']}
                    autoPlaySpeed={3000}>
                    <div className='card'>
                      <img src={proCard_Sud2024_1} alt='' />
                      <h2>Gianmarco Specchio</h2>
                      <Link
                        to='https://www.instagram.com/gianmarcosp_/'
                        target='_blank'
                        rel='noreferrer'>
                        @gianmarcosp_
                      </Link>
                    </div>
                    <div className='card'>
                      <img src={proCard_Sud2024_2} alt='' />
                      <h2>Mirko Lorefice</h2>
                      <Link
                        to='https://www.instagram.com/lorefice_mirkoo/'
                        target='_blank'
                        rel='noreferrer'>
                        @lorefice_mikcoo
                      </Link>
                    </div>
                    <div className='card'>
                      <img src={proCard_Sud2024_3} alt='' />
                      <h2>Gioele Caniglia</h2>
                      <Link
                        to='https://www.instagram.com/gioelecaniglia53_/'
                        target='_blank'
                        rel='noreferrer'>
                        @gioelecaniglia53_
                      </Link>
                    </div>
                  </Carousel>
                </div>
                <div className='weight'>
                  <h2>Middleweight</h2>
                  <Carousel
                    className='carousel'
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    removeArrowOnDeviceType={['desktop']}
                    autoPlaySpeed={3000}>
                    <div className='card'>
                      <img src={proCard_Sud2024_4} alt='' />
                      <h2>Matteo Burato</h2>
                      <Link
                        to='https://www.instagram.com/matteobburato/'
                        target='_blank'
                        rel='noreferrer'>
                        @matteobburato
                      </Link>
                    </div>
                    <div className='card'>
                      <img src={proCard_Sud2024_5} alt='' />
                      <h2>Alessio Proietti</h2>
                      <Link
                        to='https://www.instagram.com/theunconventionalcoach/'
                        target='_blank'
                        rel='noreferrer'>
                        @theunconventionalcoach
                      </Link>
                    </div>
                    <div className='card'>
                      <img src={proCard_Sud2024_6} alt='' />
                      <h2>Luca Alessandrini</h2>
                      <Link
                        to='https://www.instagram.com/lulu04_lucaaini/'
                        target='_blank'
                        rel='noreferrer'>
                        @lulu04_lucaaini
                      </Link>
                    </div>
                  </Carousel>
                </div>
                <div className='weight'>
                  <h2>Heavyweight</h2>
                  <Carousel
                    className='carousel'
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    removeArrowOnDeviceType={['desktop']}
                    autoPlaySpeed={3000}>
                    <div className='card'>
                      <img src={proCard_Sud2024_7} alt='' />
                      <h2>Alex Feltre</h2>
                      <Link
                        to='https://www.instagram.com/alex.fel3/'
                        target='_blank'
                        rel='noreferrer'>
                        @alex.fel3
                      </Link>
                    </div>
                    <div className='card'>
                      <img src={proCard_Sud2024_8} alt='' />
                      <h2>Cristiano Maiani</h2>
                      <Link
                        to='https://www.instagram.com/cristiano_maiani/'
                        target='_blank'
                        rel='noreferrer'>
                        @cristiano_maiani
                      </Link>
                    </div>
                  </Carousel>
                </div>
                <div className='weight'>
                  <h2>Female</h2>
                  <Carousel
                    className='carousel'
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    removeArrowOnDeviceType={['desktop']}
                    autoPlaySpeed={3000}>
                    <div className='card'>
                      <img src={proCard_Sud2024_9} alt='' />
                      <h2>Alessia Conetta</h2>
                      <Link
                        to='https://www.instagram.com/ale_conetta/'
                        target='_blank'
                        rel='noreferrer'>
                        @ale_conetta
                      </Link>
                    </div>
                    <div className='card'>
                      <img src={proCard_Sud2024_10} alt='' />
                      <h2>Maria Stella Aniello</h2>
                      <Link
                        to='https://www.instagram.com/aniellostella/'
                        target='_blank'
                        rel='noreferrer'>
                        @aniellostella
                      </Link>
                    </div>
                  </Carousel>
                </div>
              </div>
            )}
          </section>
        )}
      </main>
    </>
  );
};

export default HallOfFame;
