import React from 'react';
import Carousel from 'react-multi-carousel';

// Partners
import NoGravity from '../../images/partners/nogravity.jpg';

import ASI_C from '../../images/partners/asi_colore.png';
import CALI_MONZA from '../../images/partners/cali_monza.png';
import CONI from '../../images/partners/coni.png';
import SLI from '../../images/partners/SLI.png';
import UPGRADE from '../../images/partners/upgrade.png';
import UNIVERSE from '../../images/partners/universe.png';
import STREET from '../../images/partners/street_gym.png';
import GORILLA from '../../images/partners/street_gorilla.png';
import BURNINGATE from '../../images/partners/burningate.jpg';
import INSANE_STONE from '../../images/partners/insane_stone.jpg';
import CEF from '../../images/partners/cef.png';
import MIGHTY_GIM from '../../images/partners/mighty_gym.png';
import { Link } from 'react-router-dom';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2000 },
    items: 10,
  },
  desktop2: {
    breakpoint: { max: 2000, min: 1500 },
    items: 8,
  },
  desktop: {
    breakpoint: { max: 1500, min: 1216 },
    items: 8,
  },
  tablet: {
    breakpoint: { max: 1216, min: 894 },
    items: 5,
  },
  mobile2: {
    breakpoint: { max: 894, min: 587 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 587, min: 0 },
    items: 3,
  },
};

const Partners = () => {
  return (
    <section>
      <h2>Partners</h2>
      <section className='nogravity'>
        <Link
          to='https://www.instagram.com/nogravity.workout/'
          target='_blank'
          rel='noreferrer'
          className='socialCard'>
          <div className='instagram'>
            <img src={NoGravity} alt='' />
          </div>
        </Link>
      </section>
      <Carousel
        className='partners'
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        arrows={false}>
        <img src={CONI} alt='CONI' />
        <img src={ASI_C} alt='ASI' className='asi' />
        <img src={SLI} alt='SLI' />
        <img src={CALI_MONZA} alt='CALI MONZA' />
        <img src={GORILLA} alt='STREET GORILLA' />
        <img src={UNIVERSE} alt='Universe' />
        <img src={UPGRADE} alt='UPGRADE' />
        <img src={STREET} alt='STREET GYM' />
        <img src={BURNINGATE} alt='BURNINGATE' />
        <img src={INSANE_STONE} alt='INSANE STONE' />
        <img src={MIGHTY_GIM} alt='MIGHT GYM' />
        {/* Heart of Steel */}
        {/* WSWCF */}
      </Carousel>
      <section className='cef'>
        <img src={CEF} alt='CEF' />
      </section>
    </section>
  );
};

export default Partners;
